input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield !important;
}

.not-allowed {
  cursor: not-allowed;
  pointer-events: none;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.content-right {
  display: flex !important;
  justify-content: end !important;
}

.ui.fluid.input,
.ui.fluid.dropdown:not([role=combobox]) {
  height: 38px;
}

.ui.fluid.input,
.ui.fluid.dropdown {
  min-height: 38px;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.p-5{
  padding: 5px!important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-auto {
  margin-right: auto !important;
}

.btn_bg_color {
  background: #099ad9 !important;
}

.p-inputtext {
  height: 38px;
  border-radius: 0.28571429rem !important;
}

.searchCtrl > div {
  width: 100%;
  /* margin-top: 4px; */
}

.app_button_bg {
  background: #099ad9 !important;
  color: white !important;
  cursor: pointer !important;
}

.y-scroll {
  height: 100vh !important;
  overflow-y: auto;
}

.h-scroll {
  width: 100vh !important;
  overflow-x: auto;
}

.ui.grid {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.ui.grid > * {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.65 !important;
}

a[disabled] {
  pointer-events: none;
  opacity: 0.65 !important;
}

.sidebar-text {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  /* color: #1A202C !important; */
}

.sidebar-text .active {
  font-weight: 800 !important;
  /* color: #FFFFFF !important; */
}
.d-block{
  display: block;
}
.sidebar-item {
  display: flex !important;
  align-items: center !important;
  gap: 1rem !important;
}

.sideBarText {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13.8px !important;
  color: #1a202c !important;
  margin-bottom: 1rem !important;
}

.pageHeader {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #1a202c !important;
  margin-bottom: 1rem !important;
  /* Added after removing mainpage padding */
  margin-left: 1rem !important;
  margin-top:  1rem !important;
}

.sectionHeader {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #1a202c !important;
  margin-bottom: 0.5rem !important;
}

.hov:hover {
  background: #dee2e6 !important;
}
/* .p-datatable-header{
      position: absolute;
        top: 0px;
        height: 10%;
        padding: 20px;
}
.p-datatable-wrapper{
      bottom: 50px;
        position: absolute;
        top: 50px;
        overflow: auto;
        display: flex;
        height: 80%;
}
.p-datatable-footer{
      position: absolute;
        bottom: 0px;
        height: 10%;
        width: 100%;
} */
.p-menu {
  padding: 0px;
  width: auto;
}

.p-dropdown-item {
  border-top: 1px solid #fafafa !important;
  padding: 0.78571429rem 1.14285714rem !important;
  white-space: normal !important;
  word-wrap: normal !important;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: #e3f2fd;
}

.p-dropdown-item:first-child {
  border-top-width: 0 !important;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

.p-menu-separator {
  margin: 0px !important;
}

.rowCell-clickable {
  margin: 4px 4px 0px 4px;
  /* box-shadow: 0px 1px 10px #c4c4c4; */
  box-shadow: 0px 0px 3px 0 rgb(0 0 0 / 20%);
  border-radius: 7px !important;
}

.rowCell-clickable > td {
  border: none !important;
}

.rowCell-clickable:hover {
  background: #edfaff;
}

.ui.form .field > label {
  color: #1a202c !important;
}

.content-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.ui.form .fields .field>button{
  background-color: #099ad9 ;
  color: #ffffff !important;
  height: 38px;
}
.ui.sortable.table thead th {
  border-left: none !important;
}
.content-bottom-v {
  display: flex;
  align-items: flex-end;
}
.content-center-h {
  display: flex;
  justify-content: center;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.content-center-v {
  display: flex !important;
  align-items: center !important;
}
.w-45 {
  width: 45% !important;
}
.w-100 {
  width: 100% !important;
}
.w-70 {
  width: 70% !important;
}

.w-15 {
  width: 15% !important;
}

.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.w-12 {
  width: 12%;
}

.w-3 {
  width: 3%;
}
.w-55 {
  width: 55% !important;
}
.w-5 {
  width: 5% !important;
}

.w-9 {
  width: 9% !important;
}

.w-8 {
  width: 8% !important;
}

.min-w-xxs {
  min-width: 2rem;
}

.min-w-xs {
  min-width: 4rem !important;
}

.min-w-sm {
  min-width: 8rem;
}

.min-w-md {
  min-width: 12rem;
}

.min-w-lg {
  min-width: 16rem;
}

.h-100 {
  height: 100% !important;
}
.h-70 {
  height: 70% !important;
}
.h-50 {
  height: 50% !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 37.99px !important;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-radius: 0.28571429rem !important;
}

/* .action-button {
  background: white;
  line-height: 24px;
  padding: 5px;
  border: 1px solid #006dff;
  color: #1a202c;
  display: flex;
  gap: 2px;
  height: 40px !important;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
} */

.action-button {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1rem !important;
  border: 1px solid #006dff !important;
  background-color: #ffffff !important;
  border: 1px solid #006dff !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 1rem !important;
  border-radius: 12px !important;
  color: #1a202c !important;
  cursor: pointer;
}

.action-button * {
  color: #1a202c !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.action-button > img {
  height: 18px;
  margin-right: -10px;
}

.action-button:hover {
  background: #edfaff !important;
}

.no-p-left {
  padding-left: 0px !important;
}

.no-p-right {
  padding-right: 0px !important;
}

.no-p-top {
  padding-top: 0px !important;
}

.no-p-bottom {
  padding-bottom: 0px !important;
}

.p-dropdown {
  height: 38px;
}

.ui.table tbody {
  display: block;
  /* max-height: 300px; */
  /* overflow-y: overlay;
  overflow-x: auto; */
}

.p-calendar {
  width: 100%;
}

.ui.table {
  min-height: 200px;
}

.ui.table thead tr {
  display: flex;
  margin: 5px;
}

.ui.table div thead {
  width: 100%;
  display: block;
}

/* .ui.table thead, */
.ui.table tbody tr {
  display: flex;
  /* width: 100%; */
  table-layout: fixed;
  border-bottom: 0px !important;
}

.no-m-left {
  margin-left: 0px !important;
}

.no-m-right {
  margin-right: 0px !important;
}
.m-0{
   margin: 0px!important;
}
.no-m-top {
  margin-top: 0px !important;
}

.no-m-bottom {
  margin-bottom: 0px !important;
}

.no-p {
  padding: 0px !important;
}

.no-m {
  margin: 0px !important;
}

.status {
  min-width: 70px !important;
  max-width: 70px !important;
  background: #ffffff;
  border: 1px solid #06990c;
  box-sizing: border-box;
  border-radius: 31px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #06990c;
  text-align: center !important;
  padding: 5px !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.status.active {
  border: 1px solid #06990c;
  color: #06990c;
}

.status.inactive {
  border: 1px solid #c6150a;
  color: #c90c00;
}

.status.opened {
  border: 1px solid #1a8bf7;
  color: #1a8bf7;
}

.status.closed {
  border: 1px solid #f2a813;
  color: #f2a813;
}

.submitBtn {
  cursor: pointer !important;
  background: #179ed4 !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  text-align: center !important;
  font-size: 12px !important;
  color: #ffffff !important;
  opacity: 0.9 !important;
  min-height: 50px !important;
}

.thCell {
  padding: 11px !important;
  color: #718096 !important;
}

.bg_recurr {
  background-color: #e7ded9 !important;
}

.tb-header {
  background: #fbfbfb;
  border-radius: 6px;
  font-weight: 800;
  font-size: 16px;
  font-weight: 600 !important;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .rowCell {
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  /* #main {
    margin-left: 216px;
  } */
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  /* .rowCell {
    font-weight: 500 !important;
    font-size: 16px !important;
  } */

  /* #main {
    margin-left: 216px;
  } */
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
}

@media screen and (min-width: 1201px) {
}

.rowCell {
  font-style: normal !important;
  color: #1a202c;
  font-weight: 400;
  font-size: 14px;
}

.align-center {
  padding: 0 !important;
  text-align: center !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.submitBtn:hover {
  background: #179ed4 !important;
  opacity: 1 !important;
}

.ui.table thead th {
  border-bottom: none !important;
  /* background: #F5F5F5; */
}

.datePicker {
  display: inline !important;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}

.cardLayout {
  /* width: 100% !important; */
  padding: 30px !important;
  background: #ffffff;
  box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.1) !important;
  border-radius: 7px;
}

.options {
  box-shadow: 0px 1px 2px 1.3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  padding: 3px;
  cursor: pointer !important;
  width: fit-content !important;
  height: fit-content !important;
}

.plan {
  background: rgba(239, 195, 117, 0.54) !important;
  text-align: center !important;
  padding: 5px !important;
}

.plan.annual {
  background: #6fcf97 !important;
}

.plan.biAnnual {
  background: #fcd2d2 !important;
}

.plan.general {
  background: #f4d4f8 !important;
}

.appBlueBtn {
  height: 38px !important;
  margin-top: 23px !important;
  background: #099ad9 !important;
  color: white !important;
}

.card-tableLayout {
  border: none !important;
  padding-top: 0px !important;
  /* width: 100% !important; */
  -webkit-border-vertical-spacing: 5px !important;
}

.topLayout {
  padding: 15px !important;
  /* border-radius: 7px !important; */
  /* background: rgba(209, 242, 255, 0.4) !important; */
  background: #fbfbfb !important;
  margin-bottom: 20px !important;
  width: 100% !important;
  /* border-bottom: 1px solid #106489 !important; */
  /* color: #1A202C !important; */
}

.ui.search .prompt {
  border-radius: 5px !important;
}

.form-field input,
.form-field .dropdown {
  height: 38px;
}

.form-field .multiple {
  height: auto !important;
}

/* Fix for multiple select field */
.form-field .ui.multiple.search.dropdown>input.search {
  margin: 0;
}

.form-field input,
.form-field .dropdown,
.form-field textarea {
  /* font-size: 15px !important;
  color: #1a202c !important;
  font-weight: 400 !important;
  border: 1px solid #c4c4c4;
  border-radius: 5px !important; */
}

.form-field label {
  /* font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #1a202c !important; */
}

.page-container {
  height: 100% !important;
  width: 100% !important;
  overflow-y: auto !important;
  /* padding: 1rem !important; */
}

.form-button-group {
  gap: 1rem;
}

.form-button > button {
  /* margin-left: auto !important; */
  background-color: #099ad9 !important;
  color: #ffffff !important;
}

.cancel-button {
  margin-right: 1rem !important;
}

.cancel-button > button {
  background-color: rgb(224, 42, 10) !important;
  color: #ffffff !important;
}

.form-group {
  margin-bottom: 2rem !important;
}

.hidden,
.hidden * {
  visibility: hidden !important;
}

.searchBox div:first-child {
  width: 100% !important;
}

.table th {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #1a202c !important;
}

/* FIX FOR THE DROPDOWN ICON OUTLINE */

.dropdown.icon {
  outline: 0 !important;
  border: none !important;
}

/* HIDE DATEPICKER ICON */

.ant-picker-suffix {
  display: none !important;
}

button,
.button {
  cursor: pointer !important;
}

input[type="number"] {
  text-align: right !important;
}

.table-action-btn {
  padding: 10px;
  font-weight: 600;
  font-size: 16px;

  border-radius: 10px;
  color: #1a202c;
}

.list-card {
  width: auto !important;
}

/* NEW TABLE AND FORM, PLUS RESPONSIVENESS */

.ctn-main {
  max-width: fit-content;
  max-height: fit-content;
  margin-top: 4rem;
  min-width: 100%;
}

.ctn-main > h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1a202c;
}

.ctn-hdr {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
}

.ctn-hdr > div:last-of-type {
  margin-left: auto;
}

.ctn-cnt {
  /* overflow-y: auto;
  overflow-x: auto; */
  height: fit-content;
  /* max-height: 30rem; */
}

.ctn-cnt table {
  min-width: 100%;
  border-collapse: collapse;
}

.ctn-cnt table thead {
  position: sticky;
  top: 50;
}

.ctn-cnt table thead tr {
  background: #fbfbfb;
  border-radius: 6px;
  z-index: 1;
}

.ctn-cnt table th {
  position: sticky;
  top: 0px;
  background: #fbfbfb;
  z-index: 1;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #718096;
}

.ctn-cnt table td {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #1a202c;
  /* border: 1px solid #c4c4c4; */
  border-collapse: collapse;
}

.ctn-cnt table th,
.ctn-cnt table td {
  padding: 1rem;
}

.ctn-cnt table th:first-of-type,
.ctn-cnt table td:first-of-type,
.ctn-cnt table th:last-of-type,
.ctn-cnt table td:last-of-type {
  position: sticky;
  background: #ffffff;
}

.ctn-cnt table th:first-of-type,
.ctn-cnt table td:first-of-type {
  left: 0px;
}

.ctn-cnt table th:last-of-type,
.ctn-cnt table td:last-of-type {
  right: 0px;
}

.ctn-cnt table td:first-of-type,
.ctn-cnt table td:last-of-type {
  z-index: 1;
}

.ctn-cnt table th:first-of-type,
.ctn-cnt table th:last-of-type {
  z-index: 2;
  background: #fbfbfb;
}
.d-grid{
  display: grid;
}
.form-ctn {
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
}

.ui.floating.dropdown > .menu,
.ui.floating.dropdown > .menu * {
  z-index: 10 !important;
}

@media (min-width: 524px) {
  .form-ctn {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .form-ctn {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .form-ctn {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .ui.container.page-container {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .form .field {
    margin-top: 16px !important;
  }
}