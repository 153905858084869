.rightBread {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  /* padding: 6px !important; */
  margin-left: 5px;
}
.icons {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: right !important;
}

@media screen and (max-width: 768px) {
	.off-mobile, .off-mobile *, .bc, .bc * {
		display: none !important;
		visibility: hidden;
	}
}
