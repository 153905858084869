.mainPage-container {
  display: flex;
  flex-direction: column;
}
.sideBar {
  position: relative !important;
  background-color: #ddf5ff !important;
  border: none !important;
  overflow-x: hidden !important;
  padding-top: 40px !important;
  border-radius: 0px 5px 26px 0px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;

  /* height: 100vh !important; */
}
.sideBar-img {
  width: 20px;
  height: 20px !important;
  display: inline !important;
  filter: brightness(0) saturate(100%) invert(51%) sepia(15%) saturate(478%)
    hue-rotate(176deg) brightness(93%) contrast(98%);
}

.sideBar-img.active {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(248deg) brightness(106%) contrast(106%);
}

.sidebar-hide {
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  stroke-width: 1px #ddf5ff !important;
  -webkit-text-stroke: 1px #ddf5ff !important;
}

.fold {
  right: 12px;
}

.sideBarItem.active {
  padding: 10px !important;
  /* background-image: url("sidebar-active.png") */
  background-color: #fff !important;
  border-radius: 24px 0px 0px 24px;
  margin-top: 5px;
}
.sideBarItem.inactive {
  padding: 15px !important;
}
.sideBarItem {
  text-align: left !important;
  margin-left: 15%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #003c54 !important;
  cursor: pointer !important;
  z-index: 10 !important;
}

.sideBarItem i {
  z-index: 20 !important;
}

.my-1 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.mb-1 {
  margin-bottom: 25px !important;
}
.mt-5 {
  position: absolute;
  left: 20%;
  /* top: 50%; */
  bottom: 0%;
  transform: translate(-13%, -50%);
}
.img {
  margin-top: -100px;
  margin-right: -18px !important;
  z-index: 5 !important;
}

.mb-auto {
  position: absolute;
  bottom: 0;
  right: 0;
}

.sidebar-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
