.mainPage-container-body {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: #ccc;
}
.center-v-footer{
  align-content: center;
}
.content-area{
    display: grid;
    min-height: 100vh;
}
.three-rows {
  grid-template-rows: auto 1fr auto;
}
.mainPage-header {
  height: 75px !important;
}

.mainPage-footer {
	height: 40px !important;
}

.sidebar-conrol-column {
  /* width: 32px !important; */
  /* max-width: 48px !important; */
}

.sidebar-control {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-right: 1px solid rgba(0, 129, 181, 0.5) !important;
}

.sidebar-control > i {
  stroke-width: 2px white !important;
  -webkit-text-stroke: 2px white !important;
}

.mpg-content {
  /* margin: auto !important; */
  position: relative;
  overflow: auto !important;
}

.page {
  display: grid;
  min-width: 100%;
}

.page.folded {
  grid-template-columns: 3rem 1fr;
}

.page.folded .sidebar-item {
  padding: 8px !important;
  border-radius: 8px !important;
}

@media screen and (min-width: 768px) {
  .page.folded {
    grid-template-columns: 4rem 1fr;
  }

  .page.folded .sidebar-item {
    padding: 10px !important;
    border-radius: 12px !important;
  }
}

.page.opened {
  grid-template-columns: 20rem 1fr;
}
