.w-75 {
  width: 75% !important;
}
.pwdImage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login-page {
  display: grid;
  width: 100%;
  max-width: 100%;
  min-height: 100dvh;
}

.login-img-section {
  display: none;
  visibility: hidden;
  background-color: #a8e5ff;
}

@media screen and (min-width: 768px) {
  .login-page {
    grid-template-columns: repeat(2, 1fr);
  }

  .login-img-section {
    display: flex;
    align-items: center;
    visibility: visible;
    background-color: #a8e5ff;
  }

  .login-form-section {
    display: flex;
    align-items: center;
  }
}
